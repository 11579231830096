export default `
# TERMS OF USE SWAP SERVICE

THIS AGREEMENT GOVERN YOUR RELATIONSHIP WITH TRUE FLIP TECH LIMITED, A COMPANY REGISTERED IN ACCORDANCE WITH LEGISLATION OF GIBRALTAR, WITH REGISTRATION NUMBER 116315 AT 5-9 MAIN STREET, GIBRALTAR (HEREINAFTER REFERRED AS “COMPANY”) AND USE OF COMPANY’S SWAPPING SERVICE (AS SUCH TERM DEFINED BELOW) AVAILABLE ON TRUEFUTURE.IO (“WEBSITE”) AND COMPANY'S DOMAINS INCLUDED WITHIN THIS WEBSITE PROVIDED TO YOU BY THE COMPANY. YOU MAY USE THIS WEBSITE AND/OR THE SWAPPING SERVICE ONLY ON THE CONDITION THAT YOU ACCEPT ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN. PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS WEBSITE AND SWAPPING SERVICE. USING THIS WEBSITE AND/OR SWAPPING SERVICE INDICATE THAT YOU ACCEPT THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS (“TERMS OF USE”), DO NOT USE THIS WEBSITE AND/OR SWAPPING SERVICE.

BEFORE YOU PUT A CHECKMARK AT THE “I AGREE WITH THE TERMS OF USE SWAP SERVICE” BUTTON AND PRESS “NEXT”, PLEASE CAREFULLY READ THE TERMS AND CONDITIONS OF THIS AGREEMENT, AS SUCH ACTIONS ARE A SYMBOL OF YOUR SIGNATURE AND BY CLICKING ON THE “I AGREE WITH THE TERMS OF USE SWAP SERVICE” AND “NEXT” BUTTONS, YOU ARE CONSENTING TO BE BOUND BY AND ARE BECOMING A PARTY TO THIS AGREEMENT AND AGREE THAT THESE TERMS OF USE ARE ENFORCEABLE LIKE ANY WRITTEN NEGOTIATED AGREEMENT SIGNED BY YOU.  IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THESE TERMS OF USE, CLICK THE “CANCEL” BUTTON AND YOU WILL NOT BECOME A PARTY TO THESE TERMS OF USE.

### GENERAL TERMS AND ACCEPTANCE OF THIS AGREEMENT

1. The Company makes this Website including all information, graphics, documents, text, products and all other elements of the Website and all products and services offered on this Website, available for your use subject to the terms and conditions set forth in this document and any additional documents available at the Website. By accessing and using this Website, use of any Company’s Services available through this Website or clicking a checkmark at the “I agree with the Terms of Use swap service” button and pressing “Next” button you agree to be bound by the following Terms of Use and all terms and conditions contained and/or referenced herein or any additional terms and conditions set forth on this Website and all such terms shall be deemed accepted by you. If you do NOT agree to all these Terms of Use, you should NOT use this Website. If you do not agree to any additional specific terms or to particular transactions concluded through this Website, then you should NOT use the part of the Website, which contains such Content or through which such transactions may be concluded and you should not use such Content or conclude such transactions.
2. The Company can block access or restrict certain features of the Website for the User in relation to User’s place of residence or citizenship.

### ENTIRE AGREEMENT

3. These Terms contain the entire agreement and supersede all prior and contemporaneous understandings between the parties regarding the Swap Service. These Terms do not alter the terms or conditions of any other electronic or written agreement you may have with the Company for the Services or for any other Company's product or service or otherwise. In the event of any conflict between these Terms and any other agreement you may have with the Company, the terms of that other agreement will control only if these Terms are specifically identified and declared to be overridden by such other agreement. 

### AMENDMENTS

4. These Terms of Use may be amended by the Company upon notice given by one or more of the following means: through the Website at or after you login to your Account, by the email communication to the address provided by you when you setup your Account, or by written mail communication to the address on record for your Account. Failure to provide or maintain accurate or current contact information by you will not release you from responsibility to comply with these Terms of Use as amended from time to time. Please check the Terms of Use published on this Website regularly to ensure that you are aware of all terms governing your use of this Website. In addition, specific terms and conditions may apply to specific content, products, materials, Services or information contained on or available through this Website (the “Content”) or transactions concluded through this Website. Such specific terms may be in addition to these Terms of Use or, where inconsistent with these Terms of Use, only to the extent the content or intent of such specific terms is inconsistent with these Terms of Use, such specific terms will supersede these Terms of Use.

### DEFINITIONS

5. The following definitions and rules of interpretation apply in this Agreement:

| «Agreement» or «Terms of Use» | The present Agreement between You (user) and the Company |
| --- | --- |
| «Company» | True Flip Tech Limited, a company registered in accordance with legislation of Gibraltar, with registration number 116315, at 5-9 Main Street, Gibraltar |
| «Account» | A set of protected pages created as a result of the User registration on the Website, using which the User can open a virtual wallet, participate in TRUE.Metaverse, make purchases in TRUE.Marketplace and store NFTs purchased, participate in marketing events conducted by the Company and use any other services provided by the company through the Website. |
| «Cryptocurrency» or «virtual currency» | Digital cryptographic representation of assets, represented by Ethereum (ETH), Bitcoin (BTC) and any other type of cryptocurrency. |
| «Parties» | You (User) and the Company (We). |
| “Politically exposed person” or “PEP” | Foreign PEPs: individuals who are or have been entrusted with prominent public functions by a foreign country, for example Heads of state or Heads of government, senior politicians, senior government, judicial or military officials, senior executives of state owned corporations, important political party officials.
Domestic PEPs: individuals who are or have been entrusted domestically with prominent public functions, for example Heads of State or of government, senior politicians, senior government, judicial or military officials, senior executives of state owned corporations, important political party officials. |
| «Privacy Policy» | Rules of collection, storage, distribution and protection of personal data that the Company gets from the Users and that is an essential part of the Agreement which text is available at |
| «User» | An individual сapable under personal law natural person or business entity formatted in the appropriate legal form according to local legislation that is eligible to use the Website and has accepted the terms and conditions of the present Agreement with the Company. The Company reserves its right to set forth at any time and upon its own discretion special eligibility conditions or other requirements to certain Users. |
| «Website» | A group of interrelated websites owned and operated by the Company, available in the Internet via address: truefuture.io and special application based on blockchain technology. |
| «Withdrawal» | A transaction involving a transfer of Funds from the User’s Account. |
| «Swap» | A process in which TFL coin is exchanged for TFT coin according to a predetermined procedure (conditions, parameters). |
| «Staking» | The mechanics of forming liquidity for the platform with the ability to earn interest on the capital if it is locked for a certain period of time. |
| «Status» | User’s status in the ecosystem. The higher the status, the more opportunities it opens up. The status can be purchased as an NFT asset on the platform's marketplace.  |

6. In this Agreement unless the opposite is clear from the context the following rules of interpretation apply.
	a) Unless the context otherwise requires, words in the singular shall include the plural and in the plural shall include the singular;
	b) Unless the context otherwise requires, a reference to one gender shall include a reference to the other genders.
	c) References to clauses and schedules are to the clauses and schedules of this Agreement and references to paragraphs are to paragraphs of the relevant schedule;
	d) A reference to any party shall include that party’s personal representatives, successors and permitted assigns.
	e) All references to a person include firms, companies, government entities, trusts and partnerships or other or unincorporated body (whether or not having separate legal personality);
	f) The term 'including' does not exclude anything not listed;
	g) A reference to a statute or statutory provision is a reference to it as amended, extended or re-enacted from time to time;
	h) A reference to a statute or statutory provision shall include all subordinate legislation made from time to time under that statute or statutory provision.
	i) A reference to writing or written includes fax and e-mail.
	j) Any obligation on a party not to do something includes an obligation not to allow that thing to be done.
	k) Any words following this Agreement including, include, in particular, for example or any similar expression shall be construed as illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding or following that agreement.
	l) Headings of sections are for convenience only and shall not be used to limit or construe such sections. All the sections in this Agreement shall survive any termination or expiration of this Agreement. The rest of terms and definitions that can be found in the text of the Agreement should be interpreted by the Parties according to the legislation of the Gibraltar and to the general rules of interpretation of such terms accepted in the Internet network.


### RISKS NOTIFICATIONS

7. The use virtual currencies involves a significant risk. Prices can and do fluctuate on any given day. Such price fluctuations may increase or decrease the value of your assets at any given moment. Any currency, virtual or not, may be subject to large swings in value and may even become worthless. 
8. Users acknowledge that there are risks associated with using the Website including, but not limited to, the failure of hardware, software, and Internet connections. User acknowledges that the Company shall not be responsible for any communication failures, disruptions, errors, distortions or delays You may experience when using the Website however caused.
9. The tax characterization of the cryptocurrencies is uncertain. The User shall seek his own tax advice regarding the use of the Account, which may result in adverse tax consequences for the User, including, without limitation, withholding taxes, transfer taxes, value added taxes, income taxes, and similar taxes, levies, duties or other charges and tax reporting requirements. gaining access to login credentials of a hosted wallet or vault service the User uses, may be able to misappropriate the funds of the User.
10. There may be additional risks that we have not foreseen or identified in our Terms of Use.

### ELIGIBLE USERS

11. The following restrictions and conditions apply to the use of services and creating and maintaining the Account (as such term defined below):

    a. You shall not create an account in connection with the Website and/or Services (an “Account”), or access Services if you are under the age of majority to enter into this Agreement (at least 18 years of age) and meet all other eligibility criteria and residency requirements and fully able and legally capable to use the Website;

    b. You shall use the Website and/or Services if you are a PEP or any member of a PEP’s family or any close associate of a PEP, unless the Company, after being specifically notified in writing that the User is such a person, conducts further due diligence, and determines that You are eligible for use of the Website and/or Services;

    c. You shall monitor your Account to restrict use by minors, and you shall deny access to children and teenagers under the age of 18. You accept full responsibility for any unauthorized use of Website by minors in connection with your Account. You are solely responsible for any use of your cryptocurrency wallet or other payment instrument by minors;

    d. You shall not create an Account if you have already created one Account on the Website;

    e. You shall not have an Account or use Services if you have previously been removed by the Company from the Website;

    f. You shall not use Website if you are citizen or resident of: Afghanistan, Algeria, Bangladesh, Bolivia, Botswana, Bosnia & Herzegovina, Burundi, Cambodia, Central African Republic, Colombia, Democratic Republic of Congo, Egypt, Iran, Iraq, Lebanon, Lesotho, Liberia, Libya, Mali, North Korea, Pakistan, People's Republic of China, Sierra Leone, Somalia, South Sudan, Sri Lanka, Sudan, Syria, Venezuela, Vietnam, United States of America, Puerto Rico, US Virgin Islands and other US Dependent Territories, Yemen, Zimbabwe and countries or territories or individuals under the sanctions of the United Nations or the European Union (the list is available at: [https://eeas.europa.eu/Websites/eeas/files/restrictive_measures-2017-08-04.pdf](https://eeas.europa.eu/sites/eeas/files/restrictive_measures-2017-08-04.pdf)) or countries where cryptocurrency is prohibited;

    g. You shall not use your Account to advertise, solicit, or transmit any commercial advertisements, including chain letters, junk e-mail or repetitive messages (spim and spam) to any other user or third party;

    h. You shall not use your Account to engage in any illegal conduct including but not limited to activities related to money-laundering, drug trafficking, human trafficking, weapon trafficking, terrorism, securities fraud, or tax evasion. The User represents and warrants that he/she will not use the Website to assist any other party in such illegal activity; and you shall not use your Account to: distribute spam, junk communications or chain letters; reverse engineer or otherwise improperly access any of the Website’s or the platforms underlying code or technical mechanisms; cause damage to the Website or the Company through any means, including (but not limited to) through the use of malware, viruses, illegitimate credentials, phishing, brute force attacks, SQL exploits, or any other method of detrimentally intercepting, interrupting, or damaging any information or functionality related to the Website.

    i. You shall not sublicense, rent, lease, sell, trade, gift, bequeath or otherwise transfer your Account to anyone without the Company’s written permission;

    g. You shall not access or use an Account that have been sublicensed, rented, leased, sold, traded, gifted, bequeathed, or otherwise transferred from the original Account creator without Company’s consent.

    k. Notwithstanding the foregoing, the Company may refuse to provide Website or Services to any person for any reason or no reason whatsoever.

### STATUS

12. Status —  loyalty program allows token holders to discover more platform opportunities. It is implemented in the form of unique NFT statuses that can be received as a reward or purchased on the marketplace.

13. Description of statuses:
— Member — newly registered users who have not yet managed to acquire any status. They are provided with training videos and various onboarding mechanics that explain the principles of the ecosystem and describe the products within it.
— Bronze — the initial status of the user at TRUE Ecosystem. It provides the minimum required functionality that opens up the opportunities for participation in special prize drops, staking and other activities.
— Silver — main opportunities and earn mechanics become available to this type of user at TRUE Ecosystem.
— Gold — one of the core user statuses to back up the performance of TRUE Ecosystem. These users obtain an opportunity to influence the development of the project through TRUE Governance.
— Platinum — a very prestigious status at TRUE Ecosystem that has a significant rating when counting votes within True Governance as well as it gives access to the Metrics section. Includes an ability to monitor the key financial indicators of the system efficiency in real time through BI.
— Black — a top-tier status of TRUE Ecosystem. Has the highest rating in terms of vote counting within TRUE Governance. It also provides the owners with the special VIP offers.

14. User can purchase the status he needs in the true.marketplace. The status is solding for tokens.


### SWAP-STAKING

15. All users registered on the site and owning TFL tokens have access to the function of swapping TFL tokens for TFT tokens according to the following rules:

    a. At the time of the swap, funds are automatically converted from TFL to TFT in a ratio of 1 to 5 (1 TFL = 5 TFT). The resulting amount is sent to stacking.

    b. The reward rate depends on the following parameters: the period of mandatory staking, the user's status, the amount of staking and the total amount of staking funds.

    c. The period of mandatory staking depends on the user's status that he owns:
	— Member — swap is not possible
	— Bronze — 360 days
	— Silver — 270 days
	— Gold — 180 days
	— Platinum — 90 days
	— Black — 30 days

    d. Reward calculation formula:
    D — the amount of the user's deposit (TFT)
	Dall — total amount of all deposits (TFT)
	Rate1 — coefficient depending on the period:
	— 30 days: 1
	— 90 days: 1.25
	— 180 days: 1.5
	— 270 days: 1.75
	— 360 days: 2
	Rate2 — increasing coefficient, depending on the status:
	— Bronze: 1
	— Silver: 1.25
	— Gold: 1.5
	— Platinum: 1.75
	— Black: 2

	Days — period of deposit (30, 90, 180, 270, 360)
	Suser — calculated number of user points
	Sday — the sum of the points of all users of the current day (only users who activated staking the day before get into the list)
	Fday — current day stacking fund (TFT)
	Fuserday — user's reward for the day
	Fearned — amount of user reward for the current day
	APR — the amount of the user's annual profitability as of the current date
	Dpassed — days have passed since the beginning of stacking

	Suser = (D / Dall) * Rate1 * Rate2
	Fuserday = (Suser / Sday) * Fday
	APR = (Fearned + Fuserday * (Days - Dpassed)) / Days * 365 / D * 100


### DISCLAIMERS OF WARRANTIES

16. The Company is not your broker, intermediary, agent, or advisor and has no fiduciary relationship or obligation to you in connection with any activities effected by you using the Website or Services. No communication or information provided to you by the Company is intended as, or shall be considered or construed as, investment advice, financial advice, trading advice, or any other sort of advice.
17. EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY US, OUR WEBSITE AND/OR SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE EXPRESSLY DISCLAIM, AND YOU WAIVE, ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO OUR WEBSITE AND/OR SERVICES, INCLUDING THE INFORMATION, CONTENT AND MATERIALS CONTAINED THEREIN.
18. YOU ACKNOWLEDGE THAT INFORMATION YOU STORE OR TRANSFER THROUGH THE WEBSITE MAY BECOME IRRETRIEVABLY LOST OR CORRUPTED OR TEMPORARILY UNAVAILABLE DUE TO A VARIETY OF CAUSES, INCLUDING SOFTWARE FAILURES, PROTOCOL CHANGES BY THIRD PARTY PROVIDERS, INTERNET OUTAGES, FORCE MAJEURE EVENT OR OTHER DISASTERS INCLUDING THIRD PARTY DDOS ATTACKS, SCHEDULED OR UNSCHEDULED MAINTENANCE, OR OTHER CAUSES EITHER WITHIN OR OUTSIDE OUR CONTROL. YOU ARE SOLELY RESPONSIBLE FOR BACKING UP AND MAINTAINING DUPLICATE COPIES OF ANY INFORMATION YOU STORE OR TRANSFER THROUGH THE WEBSITE.
19. Some jurisdictions do not allow the disclaimer of implied terms in contracts with consumers, so some or all of the disclaimers in this section may not apply to certain users.


### PRIVACY POLICY AND PERSONAL INFORMATION

20. The Company has developed a Privacy Policy that governs the use and protection of User’s private information according to the applicable laws and good practices. The full text of the Privacy Policy is made available on the Website and can be accessed at: truefuture.io.
21. Despite all the security measures implemented by the Company, the User acknowledges that there are certain risks of the Company being attacked by electronic means in order to obtain the private information and that the Company cannot guarantee full protection. `